import { $, $$, setAttributes } from '@3pc/dom';
import { uniqueId } from 'utils/helper';
import { Accordion } from '@3pc/accordion';

const globalHeader = $('.global-header');
const initGlobalHeader = () => {
  const breakpoint = 1200;
  const randomID = uniqueId();
  // meta-navigation button (burger, close)
  const handler = $('.global-header__menu', globalHeader);
  // main-navigation
  const target = $('.global-header__bottom', globalHeader);

  if (!handler || !target) return;

  setAttributes(handler, {
    id: `mainnav-btn-${randomID}`,
    'aria-controls': `mainnav-tab-${randomID}`,
  });

  setAttributes(target, {
    id: `mainnav-tab-${randomID}`,
    'aria-labelledby': `mainnav-btn-${randomID}`,
  });

  // main-navigation BURGER
  const mainNavigationShower = new Accordion({
    target,
    handler,
    easing: 'easeInOutQuart',
    duration: 400,
    initOpened: false,
  });
  mainNavigationShower.init();

  // main-navigation ITEMS
  const mainNavigationItems = $$(
    '.global-header__navigation-item',
    globalHeader
  );

  mainNavigationItems.forEach(mainNavigationItem => {
    // main-navigation link & button (plus, minus)
    const itemHandler = $(
      '.global-header__navigation-button',
      mainNavigationItem
    );
    // main-navigation sublist
    const itemTarget = $(
      '.global-header__navigation-sublist',
      mainNavigationItem
    );

    if (!itemHandler || !itemTarget) return;

    setAttributes(itemHandler, {
      id: `subnav-btn-${randomID}`,
      'aria-controls': `subnav-tab-${randomID}`,
    });

    setAttributes(itemTarget, {
      id: `subnav-tab-${randomID}`,
      'aria-labelledby': `subnav-btn-${randomID}`,
    });

    const mainNavigationItemShower = new Accordion({
      target: itemTarget,
      handler: itemHandler,
      easing: 'easeInOutQuart',
      duration: 400,
      initOpened: false,
    });

    let showerInitted = false;

    // init mainNavigationItemShower on resize if window width is bigger than breakoint
    if (window.innerWidth < breakpoint) {
      mainNavigationItemShower.init();
      showerInitted = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth >= breakpoint && showerInitted) {
        mainNavigationItemShower.destroy();
        showerInitted = false;
      } else if (window.innerWidth < breakpoint && !showerInitted) {
        mainNavigationItemShower.init();
        showerInitted = true;
      }
    });
  });
};

if (globalHeader) {
  initGlobalHeader();
}
