const docLang = document.documentElement.lang;
export const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;
export const labels = {
  prev: {
    de: 'Zum vorherigen Bild springen',
    en: 'move to previous image',
  },
  next: {
    de: 'Zum nächsten Bild springen',
    en: 'move to next image',
  },
  close: {
    de: 'Lightbox Schließen (Esc)',
    en: 'Close lightbox (Esc)',
  },
  zoom: {
    de: 'Bild vergrößern/verkleinern',
    en: 'Zoom image in/out',
  },
  download: {
    de: 'Bild herunterladen',
    en: 'Download Image',
  },
};

const template = `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="pswp__bg"></div>
  <div class="pswp__scroll-wrap">
    <div class="pswp__container">
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
    </div>
    <div class="pswp__ui pswp__ui--hidden">
      <div class="pswp__top-bar">

        <div class="pswp_imagetitle_text">
          <span id="pswp_imagetitle" class="lightbox-title"></span>
        </div>

        <div class="pswp__buttons">
          <button class="btn pswp__button pswp__button--close" title="${labels.close[lang]}">
            <svg class="svg-close" aria-hidden="true" width="24" height="24" viewBox="0 0 1024 1024">
              <path fill="currentColor" d="M0.059 13.654l13.576-13.576 1010.359 1010.359-13.576 13.576-1010.359-1010.359z"></path>
              <path fill="currentColor" d="M0.059 1010.346l1010.359-1010.359 13.576 13.576-1010.359 1010.359-13.576-13.576z"></path>
            </svg>
            <span class="sr-only">${labels.close[lang]}</span>
          </button>
          <button class="btn pswp__button pswp__button--zoom" title="${labels.zoom[lang]}">
            <svg class="svg-zoom-in" aria-hidden="true" width="24" height="24" viewBox="0 0 1024 1024">
              <path fill="currentColor" d="M400 158.848h-19.2v221.312h-221.312v19.2h221.312v221.312h19.2v-221.312h221.312v-19.2h-221.312v-221.312z"></path>
              <path fill="currentColor" d="M1024.128 1010.432l-351.616-351.616c66.816-70.016 108.032-164.864 108.032-269.056 0-215.168-174.976-390.144-390.144-390.144s-390.016 174.976-390.016 390.144 174.976 390.144 390.144 390.144c103.936 0 198.528-40.96 268.544-107.52l351.616 351.616 13.568-13.568zM390.4 760.576c-204.544 0-370.816-166.4-370.816-370.816s166.272-370.944 370.816-370.944 370.944 166.4 370.944 370.944-166.4 370.944-370.944 370.944z"></path>
            </svg>
            <svg class="svg-zoom-out" aria-hidden="true" width="24" height="24" viewBox="0 0 1024 1024">
              <path fill="currentColor" d="M159.488 380.16h461.824v19.2h-461.824v-19.2z"></path>
              <path fill="currentColor" d="M1024.128 1010.432l-351.616-351.616c66.816-70.016 108.032-164.864 108.032-269.056 0-215.168-174.976-390.144-390.144-390.144s-390.016 174.976-390.016 390.144 174.976 390.144 390.144 390.144c103.936 0 198.528-40.96 268.544-107.52l351.616 351.616 13.568-13.568zM390.4 760.576c-204.544 0-370.816-166.4-370.816-370.816s166.272-370.944 370.816-370.944 370.944 166.4 370.944 370.944-166.4 370.944-370.944 370.944z"></path>
            </svg>
            <span class="sr-only">${labels.zoom[lang]}</span>
          </button>
        </div>
        <div class="pswp__preloader">
          <div class="pswp__preloader__icn">
            <div class="pswp__preloader__cut">
              <div class="pswp__preloader__donut"></div>
            </div>
          </div>
        </div>
      </div>
      <button class="pswp__button pswp__button--arrow--left" title="${labels.prev[lang]}">
        <span class="sr-only">${labels.prev[lang]}</span>
        <svg class="svg-chevron-left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
          <path fill="currentColor" d="M757.76 1024l-512-512 512-512 13.568 13.568-498.304 498.432 498.304 498.432-13.568 13.568z"/>
        </svg>
      </button>
      <button class="pswp__button pswp__button--arrow--right" title="${labels.next[lang]}">
        <span class="sr-only">${labels.next[lang]}</span>
          <svg class="svg-chevron-right" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1024 1024">
            <path fill="currentColor" d="M266.24 1024l-13.696-13.568 498.432-498.432-498.432-498.432 13.696-13.568 512 512-512 512z"/>
          </svg>
      </button>
      <div class="pswp__caption">
        <div class="pswp__caption-inner">
        </div>
      </div>
      <div class="pswp__counter"></div>
    </div>
  </div>
</div>
`;

export default template;
