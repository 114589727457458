import {
  Cookiebanner,
  cookiebannerTemplateMinimalConfig,
  cookiebannerLabels as defaultLabels,
} from '@3pc/cookiebanner';
import { videoConsentOverlays } from '../../04-content-components/video/video';
import { embedConsentOverlays } from '../../04-content-components/embed/embed';
import '../../../../node_modules/@3pc/cookiebanner/lib/style/cookiebanner.scss';

// const isProduction = document.body.hasAttribute('data-production');
// const siteId = document.body.getAttribute('data-trackingId');
// const matomoSiteId = isProduction ? siteId : 6;
// const matomoUrl = isProduction
//   ? 'https://matomo.ubermetrics.de/'
//   : 'https://qa.linux15.3pc.de/matomo/';
const cookiebannerLabels = {
  ...defaultLabels,
  mainPanelTitle: {
    de: 'Ihre Cookie-Einstellungen',
    en: 'Your cookie settings',
  },
  mainPanelText: {
    de: '<p>Um unsere Websites in Sachen Nutzerfreundlichkeit, Effektivität und Sicherheit für Sie zu optimieren, verwenden wir Cookies. Das sind kleine Textdateien, die auf Ihrem Datenendgerät abgelegt und in Ihrem Browser gespeichert werden.</p> <p>Darunter sind Cookies, die technisch für den Betrieb unserer Websites notwendig sind, sowie Cookies zur anonymen Webanalyse oder für erweiterte Funktionen und Services. Weitere Informationen dazu finden Sie in unserer <a href="/de/datenschutz/" class="link link--intern"><span>Datenschutzerklärung</span></a>.</p> <p>Sie entscheiden, für welche Kategorien Sie dem Einsatz von Cookies zustimmen möchten und für welche nicht. Bitte berücksichtigen Sie, dass Ihnen je nach Auswahl ggf. nicht mehr alle Funktionen unserer Websites zur Verfügung stehen.</p>',
    en: '<p>We use cookies to optimize the user-friendliness, effectiveness, and security of our websites for you. Cookies are little text files that are deposited on your data terminal and stored in your browser.</p> <p>Among these, are cookies that are essential to the technical functionality of our websites, as well as cookies for anonymous web analysis or expanded functions and services. Further information on cookies can be found in our <a href="/en/data-protection/" class="link link--intern"><span>data privacy statement</span></a> (in German only).</p> <p>You decide which categories you want to approve the use of cookies for and which ones you do not want to approve. Please keep in mind that making certain selections may mean that some of the features of our websites will no longer be available to you.</p>',
  },
  configPanelText: {
    de: '<p>Mehr zum <a class="link link--intern" href="https://ubermetrics-technologies.3pc.de/de/datenschutz/"><span>Datenschutz</span></a></p>',
    en: '<p>Further Information about <a class="link link--intern" href="https://ubermetrics-technologies.3pc.de/en/data-protection/"><span>Data Protection Policy</span></a></p>',
  },
};

const consentOverlays = [];
videoConsentOverlays && consentOverlays.push(...videoConsentOverlays);
embedConsentOverlays && consentOverlays.push(...embedConsentOverlays);

new Cookiebanner({
  nameSpace: 'ubermetrics',
  consentButtonTypes: 'required-all',
  cookiebannerTemplate: cookiebannerTemplateMinimalConfig,
  showTemplateMinimalInfoPanel: false,

  labels: cookiebannerLabels,
  consentTypes: [
    {
      name: {
        de: 'Notwendige Cookies',
        en: 'Essential Cookies',
      },
      text: {
        de: '<p>Diese Cookies sind für den Betrieb unserer Websites technisch notwendig und immer aktiviert. Hierzu zählen z.B. sogenannte Session-Cookies, die nach Ende Ihres Besuchs automatisch gelöscht werden.</p>',
        en: '<p>These cookies are essential to the technical operations of our websites and are always activated. Among these, for example, are so-called session cookies, which are automatically deleted at the end of your visit.</p>',
      },
      type: 'required',
      showInInfoPanel: true,
    },
    // {
    //   name: {
    //     de: 'Chatbot',
    //     en: 'Chatbot',
    //   },
    //   text: {
    //     de: `<p>Mithilfe des Livechats von Intercom ermöglichen wir Ihnen, auf einfache Weise mit uns zu kommunizieren. Dafür müssen Sie der Nutzung von Cookies zustimmen. Wenn Sie nicht einverstanden sind, wird kein Cookie gesetzt und der Livechat wird nicht angezeigt.</p>`,
    //     en: `<p>With the help of Intercom live chat, we enable you to directly reach out to us. To do this, you must agree to the use of cookies. If you do not agree, no cookie will be set and the live chat will not be displayed.</p>`,
    //   },
    //   type: 'chatbot',
    //   showInInfoPanel: true,
    //   trackingScripts: [
    //     {
    //       insertSelector: 'body',
    //       script:
    //         'window.intercomSettings = {\n' +
    //         'api_base: "https://api-iam.intercom.io",\n' +
    //         'app_id: "zc3yifd7",\n' +
    //         '};',
    //     },
    //     {
    //       insertSelector: 'body',
    //       script: `
    //       (function () {
    //         var w = window;
    //         var ic = w.Intercom;
    //         if (typeof ic === "function") {
    //           ic("reattach_activator");
    //           ic("update", w.intercomSettings);
    //         } else {
    //           var d = document;
    //           var i = function () {
    //             i.c(arguments);
    //           };
    //           i.q = [];
    //           i.c = function (args) {
    //             i.q.push(args);
    //           };
    //           w.Intercom = i;
    //           var l = function () {
    //             var s = d.createElement("script");
    //             s.type = "text/javascript";
    //             s.async = true;
    //             s.src = "https://widget.intercom.io/widget/zc3yifd7";
    //             var x = d.getElementsByTagName("script")[0];
    //             x.parentNode.insertBefore(s, x);
    //           };
    //           if (document.readyState === "complete") {
    //             l();
    //           } else if (w.attachEvent) {
    //             w.attachEvent("onload", l);
    //           } else {
    //             w.addEventListener("load", l, false);
    //           }
    //         }
    //       })();`,
    //     },
    //   ],
    // },
    {
      name: {
        de: 'Filme',
        en: 'Videos',
      },
      text: {
        de: `<p>Wir betten Videos von Vimeo und Youtube ein. Bei Anzeige dieser Inhalte wird eine direkte Verbindung zwischen Ihrem Browser und einem Server des jeweiligen Streaming-Dienstes hergestellt und Informationen über Ihren Besuch, Ihre IP-Adresse und Ihre Interaktion mit dem Player dort gespeichert. Mehr Informationen über die Speicherung Ihrer Daten durch Vimeo und Youtube finden Sie in unserer Datenschutzerklärung. Dort können Sie die hier vorgenommen Einstellungen auch ändern.</p>
        <p>Wenn Sie mit der Verbindungsaufnahme ihres Browsers zum Streamingdienst einverstanden sind, bestätigen Sie dies bitte. In dem Fall wird auf Ihrem Rechner ein Cookie gesetzt, um diese Einstellung zu speichern. Wenn Sie nicht einverstanden sind, wird kein Cookie gesetzt und die Videoinhalte werden nicht angezeigt.</p>`,
        en: `<p>We embed videos from Vimeo and Youtube. When displaying this content, a direct connection is established between your browser and a server of the respective streaming service and information about your visit, your IP address and your interaction with the player is stored there. More information about the storage of your data by Vimeo and Youtube can be found in our privacy policy. There you can also change the settings made here.</p>
        <p>If you agree with the connection of your browser to the streaming service, please confirm this. In this case, a cookie will be set on your computer to save this setting. If you do not agree, no cookie will be set and the video content will not be displayed.</p>`,
      },
      type: 'plyr',
    },
    {
      name: {
        de: 'Kontakt Formular',
        en: 'Contact Form',
      },
      text: {
        de: `<p>Wenn Sie uns über ein Kontaktformular anschreiben, werden Ihre Daten aus dem Formular zur Bearbeitung Ihres Anliegens verarbeitet. Wir nutzen dafür eine integrierte Lösung des Unternehmens Hubspot. Wenn Sie nicht einverstanden sind, wird kein Cookie gesetzt und das Kontaktformular wird Ihnen nicht angezeigt</p>`,
        en: `<p>If you send us a message via a contact form, your data from the form will be processed in order to handle your request. We use an integrated solution from the company Hubspot for this purpose. If you do not agree, no cookie will be set and the contact form will not be displayed to you.</p>`,
      },
      type: 'embed',
    },
    // {
    //   name: {
    //     de: 'Matomo',
    //     en: 'Matomo',
    //   },
    //   text: {
    //     de: `<p>Mit Hilfe der Matomo Cookies erheben wir statistische Daten über die Nutzung unserer Webseiten. Die Analyse dieser Daten ermöglicht es uns, unser Webangebot für Sie weiterzuentwickeln und ständig zu verbessern. Sämtliche Daten für Statistiken und Analysen erfassen wir anonymisiert, dazu wird u.a. Ihre IP-Adresse verfremdet.</p>
    //     <p><strong>Sie können Ihre Cookie-Einstellungen jederzeit ändern und die Änderung durch erneutes Laden der Internetseite aktivieren.</strong></p>`,
    //     en: `<p>With the help of Matomo cookies, we collect statistical data about the use of our websites. The analysis of this data enables us to further develop and constantly improve our website for you. We collect all data for statistics and analyses anonymously; for this purpose, among other things, your IP address is obscured.</p>
    //     <p><strong>You can modify your cookie settings at any time and activate the changes by reloading the website.</strong></p>`,
    //   },
    //   type: 'matomo',
    //   showInInfoPanel: true,
    //   trackingScripts: [
    //     {
    //       insertSelector: 'body',
    //       script:
    //         'var _paq = window._paq = window._paq || [];\n' +
    //         "_paq.push(['trackPageView']);\n" +
    //         "_paq.push(['enableLinkTracking']);\n" +
    //         '(function() {\n' +
    //         `var u="${matomoUrl}";\n` +
    //         "_paq.push(['setTrackerUrl', u+'matomo.php']);\n" +
    //         `_paq.push(['setSiteId', '${matomoSiteId}']);\n` +
    //         "var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
    //         "g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n" +
    //         '})();',
    //     },
    //   ],
    // },
  ],
  onOptIn: cookie => {
    consentOverlays &&
      consentOverlays.forEach(overlay => {
        if (overlay.consentType === cookie.type) {
          // if there is a match, activate all overlay's of that consentType:
          overlay.toggleAll();
        }
      });
  },
  onOptOut: cookie => {
    consentOverlays &&
      consentOverlays.forEach(overlay => {
        if (overlay.consentType === cookie.type) {
          // if there is a match, activate all overlay's of that consentType:
          overlay.toggleAll();
        }
      });
  },
}).init();
