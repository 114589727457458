import { $, $$ } from '@3pc/dom';

import '../../../../node_modules/@3pc/consent-overlay/lib/style/consent-overlay.scss';
import { ConsentOverlay, handleEmbed } from '@3pc/consent-overlay';

const docLang = document.documentElement.lang;
export const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;

const hubspotFormId = {
  de: '4585eb4a-5ad4-40ed-b3d8-786506f2efb1',
  en: '923e003a-4b0f-464e-b887-e75a5759eab0',
};

function loadScriptByURL(id, url, callback) {
  const isScriptExist = $(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
}

const HUBSPOT_SCRIPT_ID = 'hubspot-script';
let hubspotEmbedCount = 0;

const initEmbedConsentOverlays = () => {
  const overlays = $$('.embed [data-consent-type]');
  if (!overlays.length) return;

  const consentOverlays = overlays.map(
    overlay =>
      new ConsentOverlay({
        container: overlay,
        nameSpace: 'ubermetrics',
        onActivation: (container, consentType) => {
          // TODO internal bug in consent-overlay
          const consentSubtype = container.dataset.consentSubtype;

          if (consentType === 'embed' && consentSubtype === 'iframe') {
            handleEmbed(container, true);
          }
          if (consentType === 'embed' && consentSubtype === 'hubspot') {
            const hubspotId = `hubspot-${hubspotEmbedCount}`;

            container.dataset.hubspotId = hubspotId;
            loadScriptByURL(
              HUBSPOT_SCRIPT_ID,
              '//js-eu1.hsforms.net/forms/embed/v2.js',
              () => {
                const region = 'eu1';
                const portalId = '24898354';
                const formId = hubspotFormId[lang];

                // @ts-ignore
                // eslint-disable-next-line no-undef
                hbspt.forms.create({
                  region,
                  portalId,
                  formId,
                  target: `[data-hubspot-id=${hubspotId}] .consent-overlay__hubspot-placeholder`,
                });
              }
              // Deutsch:
              // "<script charset=""utf-8"" type=""text/javascript"" src=""//js-eu1.hsforms.net/forms/embed/v2.js""></script>
              // <script>
              // hbspt.forms.create({
              // region: ""eu1"",
              // portalId: ""24898354"",
              // formId: ""4585eb4a-5ad4-40ed-b3d8-786506f2efb1""
              // });
              // </script>"

              // Englisch:
              // "<script charset=""utf-8"" type=""text/javascript"" src=""//js-eu1.hsforms.net/forms/embed/v2.js""></script>
              // <script>
              // hbspt.forms.create({
              // region: ""eu1"",
              // portalId: ""24898354"",
              // formId: ""923e003a-4b0f-464e-b887-e75a5759eab0""
              // });
              // </script>"
            );
          }

          hubspotEmbedCount++;
        },
        onDeactivation: (container, consentType) => {
          // TODO internal bug in consent-overlay
          const consentSubtype = container.dataset.consentSubtype;

          if (consentType === 'embed' && consentSubtype === 'iframe') {
            handleEmbed(container, false);
          }
          if (consentType === 'embed' && consentSubtype === 'hubspot') {
            container.querySelector(
              '.consent-overlay__hubspot-placeholder'
            ).innerHTML = '';
            document.querySelector(`#${HUBSPOT_SCRIPT_ID}`)?.remove();
            // handleEmbed(container, false);
          }
        },
      })
  );

  consentOverlays.forEach(overlay => overlay.init());

  return consentOverlays;
};

export const embedConsentOverlays = initEmbedConsentOverlays();
