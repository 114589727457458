import EventListener from 'utils/event-listener';

class Listeners {
  constructor() {
    this.list = {};
  }

  create(eventName, id, cb) {
    if (!this.list[eventName]) {
      this.list[eventName] = {};
    }
    this.list[eventName][id] = cb;
  }

  destroy(eventName, id) {
    delete this.list[eventName][id];
  }

  fire(eventName, args) {
    const ev = this.list[eventName];
    ev &&
      Object.values(this.list[eventName]).forEach(cb => {
        typeof cb === 'function' && cb(args);
      });
  }
}

class EventBus {
  constructor() {
    this.listeners = new Listeners();
  }

  subscribe(eventName, id, cb, once) {
    this.listeners.create(eventName, id, cb);

    once && this.unsubscribe(eventName, id);
  }

  once(eventName, id, cb) {
    this.subscribe(eventName, id, cb, true);
  }

  unsubscribe(eventName, id) {
    this.listeners.destroy(eventName, id);
  }

  broadcast(eventName, args) {
    this.listeners.fire(eventName, args);
  }
}

const Bus = new EventBus();

const GLOBAL_LISTENER = [
  {
    name: 'scroll',
    events: 'scroll',
  },
  {
    name: 'load',
    events: 'load',
  },
  {
    name: 'resize',
    events: 'resize orientationchange',
  },
];

GLOBAL_LISTENER.forEach(({ name, events }) => {
  new EventListener(events, null, () => {
    Bus.broadcast(name, null);
  }).listen();
});

export default Bus;
