import { $, $$ } from '@3pc/dom';

const $$pageheaderVideos = $$('.page-header--has-video');

if ($$pageheaderVideos.length) {
  $$pageheaderVideos.forEach(pageheaderVideo => {
    const video = $('.page-header__video', pageheaderVideo);
    const playButton = $('.page-header__button-play', pageheaderVideo);

    if (!video || !playButton) return;

    // play / pause video on click
    playButton.addEventListener('click', () => {
      if (video.paused) {
        video.play();
        playButton.classList.add('page-header__button-play--paused');
      } else {
        video.pause();
        playButton.classList.remove('page-header__button-play--paused');
      }
    });
  });
}
