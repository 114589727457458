import { $, $$ } from '@3pc/dom';
import { APILazerLoader } from 'modules/lazer-loader';
import { getPlyr, PlyrPlayer } from 'modules/plyr/create-plyr';

import '../../../../node_modules/@3pc/consent-overlay/lib/style/consent-overlay.scss';
import { ConsentOverlay } from '@3pc/consent-overlay';

export const observe = targets => {
  new APILazerLoader({
    targets,
    config: {
      rootMargin: '50px 0px',
      threshold: 0.01,
    },
    cb: (api, { target }) => createPlayer(api, target),
    loadAPI: () => getPlyr(),
  }).init();
};

const initVideoConsentOverlays = () => {
  const overlays = $$('.video [data-consent-type]');
  if (!overlays.length) return;

  const consentOverlays = overlays.map(
    overlay =>
      new ConsentOverlay({
        container: overlay,
        nameSpace: 'ubermetrics',
        onActivation: (container, consentType, consentSubtype) => {
          if (
            consentType === 'plyr' &&
            (consentSubtype === 'vimeo' || consentSubtype === 'youtube')
          ) {
            const $$media = $$('.video__plyr, .video__embed-plyr', container);
            $$media.length && observe($$media);
          }
        },
        onDeactivation: (container, consentType, consentSubtype) => {
          if (
            consentType === 'plyr' &&
            (consentSubtype === 'vimeo' || consentSubtype === 'youtube')
          ) {
            const playButtons = $$('.video__embed-button', container);
            playButtons.length &&
              playButtons.forEach(playButton => {
                playButton.plyr.player.destroy();
              });
          }
        },
      })
  );

  consentOverlays.forEach(overlay => overlay.init());

  return consentOverlays;
};

export const videoConsentOverlays = initVideoConsentOverlays();

// ---- create player
const createPlayer = async (api, container) => {
  const playButton = $('.video__embed-button', container.parentElement);
  const pl = new PlyrPlayer(api, container, {
    controls: [
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'pip',
      'airplay',
      'fullscreen',
    ],
  });

  // create player
  await pl.create();

  // if bis play button dont exist, stop here
  if (!playButton) return;

  // add click event to big play button
  pl.player.on('ready', () => {
    playButton.addEventListener('click', () => pl.player.togglePlay());
  });

  // when video is playing, hide big play button
  pl.player.on('playing', () => {
    playButton.classList.add('video__embed-button--hidden');
  });

  // when video is pausing, show big play button
  pl.player.on('pause', () => {
    playButton.classList.remove('video__embed-button--hidden');
  });

  playButton.plyr = pl;
};

// for mp4 videos (no consent overlay)
const $$media = $$('.video__plyr');
$$media.length && observe($$media);
