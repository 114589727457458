// set path to iconUrl for 1) local frontend development, 2) styleguide on testing/staging, 3) typo3
const iconUrl = window.location.href.includes('localhost')
  ? '/public/assets/icons/plyr-icons.svg'
  : window.location.href.includes('styleguide')
  ? '/styleguide/public/assets/icons/plyr-icons.svg'
  : '/includes/dist/assets/icons/plyr-icons.svg';

const PLYR_DEFAULTS = {
  hideControls: true,
  settings: ['captions'],
  invertTime: false,
  iconUrl,
  iconPrefix: 'plyr',
  controls: [
    'play-large', // The large play button in the center
    'play', // Play/pause playback
    'progress', // The progress bar and scrubber for playback and buffering
    'current-time', // The current time of playback
    'mute', // Toggle mute
    'volume', // Volume control
    // 'captions', // Toggle captions
    'fullscreen', // Toggle fullscreen
  ],
};

export class PlyrPlayer {
  constructor(api, target, options = {}) {
    this.Api = api;
    this.target = target;
    this.videoOptions = { ...PLYR_DEFAULTS, ...options };
    this.player = null;
  }

  create = () => {
    this.player = new this.Api(this.target, this.videoOptions);

    return this.player;
  };
}

export const getPlyr = async () => {
  const _ = await import(/* webpackChunkName: 'plyr' */ 'plyr');
  return _.default;
};
