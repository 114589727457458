import { $ } from '@3pc/dom';
import AniScroll from 'modules/ani-scroll';
import BackToTop from 'modules/back-to-top';

const backToTopContainer = $('.back-to-top__inner');
if (backToTopContainer) {
  new BackToTop(backToTopContainer, {
    // [optional] element that will throw a callback, if scrolled above the viewport
    // offsetTopElement: $('.example'),
    // [optional] set "offsetTop" if you want to tweak the timing.
    // will use "offsetTopElement" to calculate, if "offsetTopElement" is not set, the top of the page will use instead.
    offsetTop: 1080,
    // [optional] element that will throw a callback, if its scrolled into view from below
    // [optional] set "offsetBottom" if you want to tweak the timing.
    // will use "offsetBottomElement" to calculate, if "offsetBottomElement" is not set, the bottom of the page will use instead.
    offsetBottom: 0,
    cb: (isBelowTopOffset, isBelowBottomOffset, isScrollingUp) => {
      // handles whenever the button should be fixed or static
      if (isBelowTopOffset && !isBelowBottomOffset) {
        backToTopContainer.classList.add('back-to-top__inner--active');
        backToTopContainer.classList.remove('back-to-top__inner--static');
      } else if (isBelowTopOffset && isBelowBottomOffset) {
        backToTopContainer.classList.add('back-to-top__inner--static');
        backToTopContainer.classList.remove('back-to-top__inner--active');
      } else {
        backToTopContainer.classList.remove('back-to-top__inner--active');
        backToTopContainer.classList.remove('back-to-top__inner--static');
      }

      // button is only visible on backscroll and if we reach the bottomOffset
      if (isScrollingUp) {
        if (isBelowTopOffset && !isBelowBottomOffset) {
          backToTopContainer.classList.add('back-to-top__inner--visible');
        } else if (isBelowTopOffset && isBelowBottomOffset) {
          backToTopContainer.classList.add('back-to-top__inner--visible');
        } else {
          backToTopContainer.classList.remove('back-to-top__inner--visible');
        }
      } else {
        backToTopContainer.classList.remove('back-to-top__inner--visible');

        if (isBelowTopOffset && isBelowBottomOffset) {
          backToTopContainer.classList.add('back-to-top__inner--visible');
        }
      }
    },
  }).init();

  backToTopContainer.addEventListener('click', event => {
    event.preventDefault();

    AniScroll(0, 500, 'easeOutQuad', () => {
      const logo = $('.header__logo');
      logo && logo.focus();
    });
  });
}
