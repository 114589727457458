/**
 * Listen for multiple events and do some work debounced by rAF.
 *
 * Usage:
 * const Listener = new EventListener(
 *   // event we want to listen to
 *   'load scroll orientationchange',
 *   // values we are interested in
 *   () => ({
 *     ev: event,
 *     scrollY: window.scrollY,
 *     width: window.innerWidth,
 *   }),
 *   // callback fn has values available
 *   values => {
 *     console.log('got values', { ev, scrollY, width });
 *   },
 * );
 *
 * Listener.listen();
 */

export default class EventListener {
  constructor(events, prop, cb) {
    this.events = events;
    this.prop = prop;
    this.cb = cb;
    this.latestKnownValue = 0;
    this.ticking = false;
    this.currentValue = 0;
  }

  // Adds multiple event listeners at once.
  addListener = (el = window, listeners, func, capture = false) => {
    listeners
      .split(' ')
      .forEach(listener => el.addEventListener(listener, func, capture));
  };

  // Removes multiple event listener at once.
  removeListener = (el = window, listeners, func, capture) => {
    listeners
      .split(' ')
      .forEach(listener => el.removeEventListener(listener, func, capture));
  };

  listen = () => {
    this.addListener(window, this.events, this.onEvent, false);
  };

  destroy = () => {
    this.removeListener(window, this.events, this.onEvent, false);
  };

  update = () => {
    this.ticking = false;
    this.currentValue = !this.prop ? null : this.latestKnownValue;
    return this.cb(this.currentValue);
  };

  requestTick = () => {
    if (!this.ticking) {
      requestAnimationFrame(this.update);
    }
    this.ticking = true;
  };

  onEvent = event => {
    if (this.prop) this.latestKnownValue = this.prop(event);
    this.requestTick();
  };
}
