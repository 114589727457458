import { $$ } from '@3pc/dom';
import Lightbox from 'modules/lightbox/Lightbox';

const textmedias = $$('.textmedia');

if (textmedias.length) {
  textmedias.forEach(textmedia => {
    new Lightbox(textmedia, {
      selector: '.textmedia__lightbox-toggle',
    }).init();
  });
}
